// @file
// Waranara takeover menu.

'use strict';

(function ($) {
  const menuOuter = $('.waranara-takeover-menu');
  const toggleButton = $('.waranara-takeover-menu__toggle-button');

  /**
   * Determine if the menu is currently open.
   *
   * @return boolean
   *   TRUE if open, FALSE if not.
   */
  const menuIsOpen = () => toggleButton.hasClass('active');

  /**
   * Control what happens to all elements when you open and close the menu.
   *
   * @param boolean openMenu
   *   Whether the menu should be opened.
   * @param boolean focusToggleButton
   *   Whether to focus the toggle button.
   */
  const toggleMenu = (openMenu, focusToggleButton) => {
    const body = $('body');
    const container = $('.waranara-takeover-menu__container');
    const overlay = $('.waranara-takeover-menu__overlay');
    const list = $('.waranara-takeover-menu__container .waranara-takeover-menu__list');
    const toggleContainer = $('.waranara-takeover-menu__toggle-container');

    // To open the menu, we have to do the following.
    // 1. Stop all animations on the list.
    // 2. Add the active class to the toggle button.
    // 3. Apply the scroll lock to the body.
    // 4. Add the active class to the container.
    // 5. Add the active class to the overlay.
    // 6. Add the bounce effect class to the toggle container.
    // 7. Expand the list open.
    list.stop(true, true);
    if (openMenu) {
      toggleButton.addClass('active')
        .prop('aria-expanded', true);
      body.addClass('body--scroll-lock');
      container.addClass('waranara-takeover-menu__container--active');
      overlay.removeClass('waranara-takeover-menu__overlay--deactivating')
        .addClass('waranara-takeover-menu__overlay--active');
      toggleContainer.toggleClass('bounce-effect');
      list.attr('aria-hidden', 'false')
        .slideDown('slow', 'swing', () => {
          list.css({ display: 'block' });
        });
    } else {
      toggleButton.removeClass('active')
        .prop('aria-expanded', false);
      overlay.addClass('waranara-takeover-menu__overlay--deactivating')
        .removeClass('waranara-takeover-menu__overlay--active');
      toggleContainer.toggleClass('bounce-effect');

      // Note that we're removing the container active class and
      // body scroll lock after the slide toggle completes.
      // This allows the animation to complete before the overlay
      // is removed and scrolling is restored.
      list.attr('aria-hidden', 'true')
        .slideUp('slow', 'swing', () => {
          container.removeClass('waranara-takeover-menu__container--active');
          body.removeClass('body--scroll-lock');
          overlay.removeClass('waranara-takeover-menu__overlay--deactivating');
        });
    }

    if (focusToggleButton) {
      toggleButton.focus();
    }
  };

  // Handle the toggle button behaviour.
  toggleButton.click((e) => {
    e.preventDefault();
    toggleMenu(!menuIsOpen());
  });

  // Test if focus has left open menu by checking if the currently
  // focused element isn't inside the menu bounds.
  $(document).focusin((e) => {
    if (menuIsOpen() && !$(menuOuter).get(0).contains(document.activeElement)) {
      toggleButton.focus();
    }
  });

  // Close menu if it's open and the escape key is pressed.
  $(document).keyup((e) => {
    if (e.keyCode === 27 && menuIsOpen()) {
      toggleMenu(false, true);
    }
  });
})(jQuery);
